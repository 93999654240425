<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <b-row>
            <!-- <b-col v-if="isSuperCashier" cols="12" md="3">
                <b-form-group label="Almacen" label-for="warehouse" label-class="font-weight-bold">
                    <v-select
                        :disabled="!isSuperCashier"
                        id="warehouse" 
                        v-model="warehouse_id"                       
                        :options="wareHouses"                        
                        label="nombre"
                        :reduce="option => option.almacen_Id"
                        :clearable="false"
                        :searchable="false"
                        class="select-size-sm"
                        placeholder="Selecciona una opción"
                        @input="selectedWarehouse">
                    </v-select>
                </b-form-group>
            </b-col> -->

            <b-col cols="12" md="4">
                <b-form-group label="Ingresar Código de Barras de Factura" label-for="search-invoice">
                    <b-input-group class="input-group-merge">
                        <b-form-input :readonly="invoiceData != undefined && hasInvoiceData" ref="searcInvoice" size="sm"
                            id="search-invoice" v-model="searchQuery" placeholder="Ingresa o escanea el código de barras"
                            @blur="handleBlur"
                            @keydown="handleKeydown"
                            @keydown.enter="searchInvoices" autocomplete="off" inputmode="numeric" autofocus :type="isAdministrator ? 'text' : 'password'" />
                        
                            <b-input-group-append>
                            <b-button v-if="!searchQuery" @click="activeSearchCustomer = true" size="sm" variant="outline-primary">
                                <feather-icon icon="SearchIcon" />
                            </b-button>

                            <b-button v-else size="sm" @click="resetForm" variant="outline-danger">
                                <feather-icon icon="XIcon" />
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>                   
            </b-col>

            <!-- Info Detail -->
            <b-col v-if="invoiceData != undefined && hasInvoiceData" cols="12" :md="isSuperCashier ? 5 : 8">
                <table class="border text-small small w-100 ">
                    <tr>
                        <th>
                            <span class="font-weight-bold">Importe</span>
                        </th>
                        <td>
                            {{ formatCurrency(invoiceData.importe_Neto) }}
                        </td>
                        <th>
                            <span class="font-weight-bold">Fecha</span>
                        </th>
                        <td class="text-capitalize">{{ formatDate(invoiceData.fecha) }}</td>
                    </tr>

                    <tr>
                        <th>
                            <span class="font-weight-bold">Saldo Fact.</span>
                        </th>
                        <td v-if="invoiceData.doctoVencimiento">
                            {{ formatCurrency(invoiceData.doctoVencimiento.saldo) }}
                        </td>
                        <td v-else>
                            {{ formatCurrency(invoiceData.balance) }}
                        </td>
                        <th>
                            <span class="font-weight-bold">Vendedor</span>
                        </th>
                        <td class="text-truncate">{{ invoiceData.vendedor }}</td>
                    </tr>

                    <tr>
                        <th>
                            <span class="font-weight-bold">Folio</span>
                        </th>
                        <td>
                            {{ invoiceData.folio }}
                        </td>
                        <th>
                            <span class="font-weight-bold">Estatus</span>
                        </th>
                        <td> <strong>{{ invoiceData.estatus }}</strong> - {{ invoiceData.almacen }}</td>
                    </tr>
                </table>                
            </b-col>

            <!-- Tabs Detail -->
            <b-col v-if="showDetail && articles.length > 0" cols="12" md="12" class="mt-1">

                <b-tabs fill v-model="activeTab">
                    <b-tab>

                        <template #title>
                            <feather-icon icon="BoxIcon" />
                            <span>Articulos</span>
                        </template>

                        <articles-invoice :loading="loading" :articles="articles" :isEdit="isEditInvoice" :isReturn="isReturn" 
                        :hasRouting="hasDeliveryRounting" :showAutocompleteCharge="showAutComplete"/>
                    </b-tab>
                    <b-tab @click="getReturns">

                        <template #title>
                            <feather-icon icon="RefreshCwIcon" />
                            <span>Vueltas</span>
                        </template>

                        <b-table ref="refReturnListTable"
                            class="position-relative b-table-responsive mb-0 text-small small" :items="returns"
                            responsive selectable select-mode="single" :fields="columnReturns" primary-key="articulo_Id"
                            show-empty empty-text="No se encontraron resultados" :busy.sync="loading" stacked="md"
                            small>

                            <!-- Empty -->
                            <template slot="empty">
                                <h6 class="text-center mt-1">
                                    No se encontraron registros
                                </h6>
                            </template>

                             <!-- Fecha Salida -->
                             <template #cell(fecha_Salida)="data">
                                {{ formatDate(data.item.fecha_Salida) }}
                            </template>
                        </b-table>
                    </b-tab>
                    <b-tab title="Devoluciones" @click="getReturnsByTypeDocto">

                        <template #title>
                            <feather-icon icon="RotateCcwIcon" />
                            <span>Devoluciones</span>
                        </template>

                        <b-table ref="refReturnItemsListTable"
                            class="position-relative b-table-responsive mb-0 text-small small" :items="returnItems"
                            responsive selectable select-mode="single" :fields="columnReturnItems" primary-key="articulo_Id"
                            show-empty empty-text="No se encontraron resultados" :busy.sync="loading" stacked="md"
                            small>

                            <!-- Empty -->
                            <template slot="empty">
                                <h6 class="text-center mt-1">
                                    No se encontraron registros
                                </h6>
                            </template>
                        </b-table>
                       
                    </b-tab>
                    <b-tab title="Cliente" @click="getCustomer">

                        <template #title>
                            <feather-icon icon="UserIcon" />
                            <span>Cliente</span>
                        </template>

                        <b-row v-if="customer">
                            <b-col md="6">
                                <b-form-group label="Nombre Cliente" label-for="mc-first-name">
                                <b-form-input
                                    readonly
                                    size="sm"
                                    :value="customer.nombre"
                                    id="mc-first-name"
                                    placeholder="Nombre Cliente"
                                />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Límite" label-for="limite_Cliente">
                                <b-form-input
                                    readonly
                                    size="sm"
                                    :value="`${formatCurrency(customer.limite_Credito)}`"
                                    id="limite_Cliente"
                                    placeholder="$0.00"
                                />
                                </b-form-group>
                            </b-col>

                            <b-col md="3">
                                <b-form-group label="Saldo" label-for="saldo">
                                <b-form-input
                                    readonly
                                    size="sm"
                                    :value="`${formatCurrency(customer.saldo_Cliente)}`"
                                    id="saldo"
                                    placeholder="$0.00"
                                />
                                </b-form-group>
                            </b-col>

                            <b-col md="3">
                                <b-form-group label="RFC" label-for="rfc_curp">
                                <b-form-input
                                    readonly
                                    size="sm"
                                    :value="customer.rfc_Curp"
                                    id="rfc_curp"
                                    placeholder="RFC"
                                />
                                </b-form-group>
                            </b-col>

                            <b-col md="3">
                                <b-form-group label="Clave" label-for="clave">
                                <b-form-input
                                    readonly
                                    size="sm"
                                    :value="customer.clave_Cliente"
                                    id="clave"
                                    placeholder="Clave Cliente"
                                />
                                </b-form-group>
                            </b-col>

                            <b-col md="3">
                                <b-form-group label="Tipo Crédito" label-for="tipo_credito">
                                <b-form-input
                                    readonly
                                    size="sm"
                                    :value="customer.tipo"
                                    id="tipo_credito"
                                    placeholder="Tipo Crédito"
                                />
                                </b-form-group>
                            </b-col>

                            <b-col md="3">
                                <b-form-group label="Condiciones" label-for="condiciones">
                                <b-form-input
                                    readonly
                                    size="sm"
                                    :value="customer.plazo"
                                    id="condiciones"
                                    placeholder="Condiciones"
                                />
                                </b-form-group>
                            </b-col>

                            <b-col md="3">
                                <b-form-group label="Cobrador" label-for="cobrador">
                                <b-form-input
                                    readonly
                                    size="sm"
                                    :value="customer.cobrador"
                                    id="cobrador"
                                    placeholder="Cobrador"
                                />
                                </b-form-group>
                            </b-col>

                            <b-col md="3">
                                <b-form-group label="Vendedor" label-for="vendedor">
                                <b-form-input
                                    readonly
                                    size="sm"
                                    :value="customer.vendedor"
                                    id="vendedor"
                                    placeholder="Vendedor"
                                />
                                </b-form-group>
                            </b-col>

                            <b-col md="3">
                                <b-form-group label="Teléfono" label-for="telefono">
                                <b-form-input
                                    readonly
                                    size="sm"
                                    :value="customer.telefono1"
                                    id="telefono"
                                    placeholder="Teléfono"
                                />
                                </b-form-group>
                            </b-col>

                            <b-col md="3">
                                <b-form-group label="Estatus" label-for="estatus">
                                <b-form-input
                                    readonly
                                    size="sm"
                                    :value="customer.estatus"
                                    id="estatus"
                                    placeholder="Estatus"
                                />
                                </b-form-group>
                            </b-col>

                            <b-col md="12">
                                <b-form-group label="Dirección" label-for="address">
                                <b-form-input
                                    id="address"
                                    readonly
                                    size="sm"
                                    :value="customer.address"
                                    placeholder="Dirección"
                                />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>

                    <!-- Historial de Facturas -->
                    <b-tab title="Historial de Facturas">
                        <template #title>
                            <feather-icon icon="ClockIcon" />
                            <span>Historial de Facturas</span>
                        </template>

                        <b-table :fields="columnTransfers" class="position-relative b-table-responsive mb-0 text-small small"
                        :items="transfers"
                        show-empty empty-text="No se encontraron resultados" :busy.sync="loading" stacked="md" small>

                            <template slot="empty">
                                <h6 class="text-center mt-1">
                                    No se encontraron registros
                                </h6>
                            </template>

                            <template #cell(tiempo_Transcurrido)="data">
                                <span class="text-capitalize">{{ mostrarTiempoTranscurrido(data.item.fecha_Documento, data.item.fecha_Alta) }}</span>
                            </template>

                            <template #cell(fecha_Alta)="data">
                                <span class="text-capitalize">{{ formatDate(data.item.fecha_Alta) }}</span>
                            </template>
                        </b-table>
                    </b-tab>

                    <!-- Comentarios sobre la factura -->
                    <b-tab>
                        <template #title>
                            <feather-icon icon="MessageSquareIcon" />
                            <span>Comentarios</span>
                        </template>

                        <b-form-group label="Ingresar un comentario" label-for="add-comments">
                            <b-input-group class="input-group-merge">
                                <b-form-input v-model="newComments" size="sm" id="add-comments" placeholder="Ingresa un comentario" autocomplete="off" autofocus type="text" trim />
                                <b-input-group-append v-if="newComments != ''">
                                    <b-button @click="storeComments" size="sm" variant="outline-primary">
                                        <feather-icon icon="SaveIcon" />
                                        Guardar
                                    </b-button>
                                    <b-button size="sm" @click="newComments = ''" variant="outline-danger">
                                        <feather-icon icon="XIcon" />
                                        Limpiar
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>

                        <!-- Lista de comentarios de la factura -->
                        <b-table class="position-relative b-table-responsive mb-0 text-small small"
                            :items="invoiceData.centralRutasEntregasObservaciones" responsive :fields="[
                                { key: 'usuario_Nombre', label: 'Creado Por' },
                                { key: 'comentarios', label: 'Comentario' },
                                { key: 'fecha_Alta', label: 'Fecha Registro' },
                            ]"
                            show-empty empty-text="No se encontraron resultados" :busy.sync="loading" small>
                            <template slot="empty">
                                <h6 class="text-center mt-1">
                                    No se encontraron registros
                                </h6>
                            </template>

                            <template #cell(fecha_Alta)="data">
                                <span class="text-capitalize">{{ formatDate(data.item.fecha_Alta) }}</span>
                            </template>
                        </b-table>                        
                    </b-tab>
                </b-tabs>
            </b-col>
        </b-row>

        <!-- 
            Custom butoms 

            Si el tipo documento es de V => venta se debe poder dar las salidas pero no se puede enrutar lo cual el boton de enrutar factura debe estar
            deshabilitado            
        -->

        <!-- Comments -->
        <b-row v-if="isEditInvoice">
            <b-col cols="12" md="12">
                <b-form-group label="Ingresar Comentario (opcional)" label-for="search-invoice">
                    <b-form-textarea v-model="comments" size="sm" rows="2" cols="30" placeholder="Ingresar un breve comentario"/>
                </b-form-group>
            </b-col>
        </b-row>

        <!-- Actions -->
        <b-row v-if="!isAuditor">
            <b-col v-if="showDetail && articles.length > 0" cols="12" md="12" class="mt-2">
                <div class="demo-inline-spacing">
                    <b-button ref="editButton" :disabled="isDetail" :block="isMobile" v-if="!isEditInvoice && isYard && countPending > 0"
                        @click="activarEdition" size="sm" variant="outline-danger" class="text-end float-right"
                        v-b-tooltip.hover.top="showTooltip ? `Salida ctrl + e` : ``">
                        <feather-icon icon="EditIcon" />
                        Generar Salida
                    </b-button>

                    <b-button ref="allButton" :disabled="isDetail" :block="isMobile" v-if="!isEditInvoice && countPending > 0 && !isAutorizator"
                        @click="generateTotalOutput" size="sm" variant="warning" class="text-end float-right _mr-1"
                        v-b-tooltip.hover.top="showTooltip ? `Salida Total ctrl + a` : ``">
                        <feather-icon icon="PlusIcon" />
                        Generar Salida Total
                    </b-button>

                    <b-button ref="saveButton" :disabled="isDetail" :block="isMobile" 
                        v-if="isEditInvoice" @click="updateRouting(isAll = false)" size="sm" variant="primary" class="text-end float-right"
                        v-b-tooltip.hover.top="showTooltip ? `Guardar F12` : ``">
                        <feather-icon icon="SaveIcon" />
                        Guardar
                    </b-button>  

                    <b-button :disabled="isDetail" :block="isMobile" 
                        v-if="(deliveredQuantity > 0 && !isEditInvoice) || (isAutorizator && !isEditInvoice)" @click="activateRefund" size="sm" variant="danger" class="text-end float-right">
                        <feather-icon icon="MinusIcon" />
                        Devolución
                    </b-button>
                    
                    <b-button :block="isMobile" 
                        :disabled="isDetail"
                        v-if="isInvoice && countPending > 0 && !isEditInvoice && !cliente_Pasa && !hasRouting && !isViewRouting" @click="routeInvoice" size="sm" variant="primary" class="text-end float-right">
                        <feather-icon icon="PlusIcon" />
                        Enrutar Factura
                    </b-button>

                    <!-- Clientes Pasan -->
                    <b-button :block="isMobile"    
                        :disabled="isDetail"
                        v-if="isInvoice && countPending > 0 && !isEditInvoice && !hasRouting && !cliente_Pasa && (isSuperCashier || isCahsier || isAdministrator)"
                        size="sm" variant="dark" class="text-end float-right"
                        @click="setCustomerPass">
                        <feather-icon icon="CornerUpRightIcon" />
                        Clientes Pasan
                    </b-button>

                    <b-button :disabled="isDetail"
                        v-if=" canAccess(38) && hasRoutingClosed && !documento_Recibido && !isEditInvoice && hasRouting && !cliente_Pasa && (isAuditor || isAdministrator)"
                        :block="isMobile" size="sm" variant="dark"
                        class="mr-1"
                        @click="markAsReceivedDocument">
                        Documento Recibido
                    </b-button>

                    <b-button :disabled="isDetail" :block="isMobile" v-if="isEditInvoice" @click="cancelEditArticles" size="sm" variant="secondary" class="text-end float-right _mr-1">
                        <feather-icon icon="XCircleIcon" />
                        Cancelar
                    </b-button>

                    <b-button :block="isMobile" v-if="!isEditInvoice" @click="resetForm" size="sm" variant="outline-secondary" class="text-end float-right _mr-1">
                        <feather-icon icon="XCircleIcon" />
                        Limpiar
                    </b-button>

                </div>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col cols="12" md="12">
                
                <!-- Document received -->
                <b-button 
                    :disabled="isDetail"
                    v-if=" canAccess(38) && hasRoutingClosed && !documento_Recibido && !isEditInvoice && hasRouting && !cliente_Pasa && (isAuditor || isAdministrator)"
                    :block="isMobile" size="sm" variant="dark"
                    class="mr-1"
                    @click="markAsReceivedDocument">
                    Documento Recibido
                </b-button>

                <b-button :block="isMobile" v-if="articles.length" @click="resetForm" size="sm" variant="secondary">
                    <feather-icon icon="XCircleIcon" />
                    Limpiar
                </b-button>
            </b-col>
        </b-row>

        <!-- Modal -->
        <authorization-modal :active="isActiveAuthorization" @close="isActiveAuthorization = false" @store="storeReturn" />


        <!-- Modal Search Invoice -->
        <b-modal id="modal-search-invoice" ref="modal-search-invoice" title="Búsqueda de Facturas" @hidden="close"
            ok-only ok-title="Aceptar" size="lg" no-close-on-backdrop>
            <div class="m-2">
                <b-row>
                    <b-col cols="12" md="12">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-input-group>
                                <b-form-input autofocus v-model="searchQuery" class="d-inline-block _mr-1"
                                    placeholder="Buscar por nombre" @keyup.enter="searchInvoices" autocomplete="off" />
                                <b-input-group-prepend>
                                    <b-button variant="primary" @click="searchInvoices">
                                        <feather-icon icon="SearchIcon" />
                                    </b-button>
                                </b-input-group-prepend>
                            </b-input-group>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </b-modal>

        
        <!-- Search invoices by customers -->
        <search-by-customer :active="activeSearchCustomer" @selected="selectInvoice"
            @close="activeSearchCustomer = false" />
    </b-overlay>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
    BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar, BPagination, BFormSelect,
    BBadge, BCardSubTitle, BCardTitle, BTabs, BTab,  BTooltip, VBTooltip,
} from 'bootstrap-vue'
import Swal from "sweetalert2";
import "animate.css";
import EventBus from "@/event-bus"
import router from "@/router";
import Ripple from 'vue-ripple-directive'
import vSelect from "vue-select";
import { VueGoodTable } from 'vue-good-table'
import { heightTransition } from '@core/mixins/ui/transition'
import store from '@/store'
import { mapGetters, mapActions } from 'vuex';
import ArticlesInvoice from '@/@core/components/invoices/ArticlesInvoice.vue';
import AuthorizationModal from '@/@core/components/AuthorizationModal.vue'
import SearchByCustomer from "@core/components/invoices/SearchByCustomer.vue"

import CustomersProvider from "@/providers/Customers"
const CustomersResource = new CustomersProvider()

import DoctosPVProvider from "@/providers/DoctosPV"
import FeatherIcon from '../feather-icon/FeatherIcon.vue';
const DoctosPVResource = new DoctosPVProvider()

import DeliveryRoutesProvider from '@/providers/DeliveryRoutes'
import moment from 'moment';
import { nextTick } from '@vue/composition-api';
const DeliveryRoutesResource = new DeliveryRoutesProvider();

export default {
    name: 'SearchInvoices',
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
        'b-modal': VBModal,
        'b-toggle': VBToggle,
    },
    components: {
        BRow,
        BSidebar,
        BCol,
        BCard,
        BCardBody,
        BCardText,
        BButton,
        BAlert,
        BLink,
        BOverlay,
        BSpinner,
        BTable,
        BTableLite,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BModal,
        VBModal,
        BForm,
        BIcon,
        BInputGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BFormTextarea,
        BFormTags,
        BCardCode,
        BAvatar,
        VueGoodTable,
        BPagination,
        BFormSelect,
        BBadge,
        BCardSubTitle,
        BCardTitle,
        FeatherIcon,
        BTabs,
        BTab,
        BTooltip, 
        VBTooltip,

        //
        ArticlesInvoice,
        AuthorizationModal,
        SearchByCustomer,
        vSelect,
    },
    mixins: [heightTransition],
    props: {
        showArticles: {
            type: Boolean,
            default: false,
        },
        showDetail: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
            invoiceData: {},
            articles: [],
            searchQuery: null,
            activeSearchInvoice: false,
            columns: [
                {
                    key: 'um_Total',
                    label: 'Um Total',
                    tdClass: 'text-center',
                },
                {
                    key: 'articulo',
                    label: 'Articulo',
                },
                {
                    key: 'um',
                    label: 'Um',
                    tdClass: 'text-center',
                },
                {
                    key: 'entregado',
                    label: 'Entregado',
                    tdClass: 'text-center',
                },
                {
                    key: 'cargado',
                    label: 'Cargado',
                },
                {
                    key: 'pendiente',
                    label: 'Pendiente',
                    tdClass: 'text-center',
                },
                // {
                //     key: 'estatus_Retorno',
                //     label: 'Estatus',
                // },
            ],
            columnReturns: [
                { key: 'nombre_Usuario', label: 'Creado Por' },
                { key: 'articulo_Nombre', label: 'Articulo' },
                { key: 'unidades', label: 'Unidad' },
                { key: 'fecha_Salida', label: 'Fecha Salida', tdClass: 'text-capitalize' },
                //{ key: 'fecha_Retorno', label: 'Retorno' },
                { key: 'nombre_Chofer', label: 'Chofer' },
                { key: 'nombre_Chofer', label: 'Verifico' },
            ],
            columnReturnItems: [
                { key: 'articulo_Id', label: 'Articulo Id' },
                { key: 'nombre', label: 'Articulo' },
                { key: 'unidades', label: 'Unidades' },
                { key: 'unidades_Dev', label: 'Unidades Dev' },
            ],
            returns: [],
            searchTerm: '',
            customer: null,
            returnItems: [],
            isAutoCompleteCharge: false,
            delivery_rounting: {},
            countPending: 0,
            countDelivered: 0,
            isEditInvoice: false,
            columnTransfers: [
                {
                    key: 'usuario_Nombre',
                    label: 'Generado Por',
                },
                {
                    key: 'fecha_Alta',
                    label: 'Fecha Hora',                    
                },
                {
                    key: 'tiempo_Transcurrido',
                    label: 'Tiempo Transcurrido'
                },
                {
                    key: 'tipo_Docto',
                    label: 'Tipo Documento'
                },
                {
                    key: 'accion',
                    label: 'Acción',
                },
                {
                    key: 'chofer_Nombre',
                    label: 'Recibio',
                },
                {
                    key: 'camion_Nombre',
                    label: 'Camión'
                },                
               
            ],
            transfers: [],
            activeTab: 0,
            isMobile: false,
            isReturn: false,
            isActiveAuthorization: false,
            isOutput: false,
            estatus: null,
            isDetail: false,
            showAutComplete: false,
            wareHouses: [],
            warehouse_id: null, //Almacen default del usuario,
            comments: null,
            newComments: '',
            activeSearchCustomer: false,
            cliente_Pasa: false,
            documento_Recibido: false,
        }
    },
    created () {

        EventBus.$on('closingInvoice', async(e) => {
           console.log('closingInvoice', e)
           await this.resetForm()
        });

        EventBus.$on('selectRoutingEstatus', (e) => {
            
        });

        EventBus.$on('selectedDocto', (e) => {
            console.error(e)
            if (e) {
                this.estatus = e.estatus
                this.searchQuery = e.docto_pv_Id
                this.isDetail = true
                this.$nextTick(async() => {
                    await this.searchInvoices()
                })
            }            
        });

        EventBus.$on('changeOperator', (e) => {
            if (e) {
                if (!this.searchQuery) {
                    this.searchQuery = e
                }                
                
                this.$nextTick(async() => {
                    if (this.searchQuery) {                    
                        await this.searchInvoices()
                        await this.getReturns()
                    }
                })
            }
        })

        this.$nextTick(() => {
          this.$refs.searcInvoice.focus();
        });
    },
    computed: {
        ...mapGetters({
            getIsInputFocused: 'auth/getIsInputFocused',
        }),
        deliveredQuantity() {
            return this.articles.reduce((carry, a) => {
                return carry + a.entregado
            }, 0)
        },
        hasInvoiceData() {
            return Object.keys(this.invoiceData).length > 0;
        },
        hasDeliveryRounting() {
            return Object.keys(this.delivery_rounting).length > 0;
        },
        canRouting() {// permission 18 => Módulo de Enrutamiento
            return this.canAccess(18)
        },
        isViewRouting() {
            return router.currentRoute.name == 'apps-routings-outputs' //|| router.currentRoute.name == 'apps-routings'
        }, 
        hasRouting () {
            if (Object.keys(this.delivery_rounting).length > 0) {
                return this.delivery_rounting.existe_Ruta
            }
            return false
        }, 
        hasRoutingClosed () {
            if (Object.keys(this.delivery_rounting).length > 0) {
                return this.delivery_rounting.estatus === 'CERRADA'
            }
            return false
        },     
        canRouting() {

        },
        isYard() {
            //Es patio, cajera y/o es administrador
            return (
                store.getters['auth/getUser'].customRol.id == "5c1e9a77-e7a1-40fe-ba71-6d5c65cea4e0" || //Patio
                store.getters['auth/getUser'].customRol.id == "849fb91e-cab4-4894-a1c3-aedf03b47c34" || //Administrador
                store.getters['auth/getUser'].customRol.id == "01ab07a3-50bf-483c-9fb0-b21c67b1b570" || //Super Cajera
                store.getters['auth/getUser'].customRol.id == "7714b05a-aa32-4b9c-9ca1-83036bac0b5d" || //Gerente
                store.getters['auth/getUser'].customRol.id == "9da2306d-48fc-4359-af70-27fb9eeb5440" || //Autorizador
                store.getters['auth/getUser'].customRol.id == "ac1765c2-1473-44eb-9031-b91e40323bec" || //Encargado de patio
                store.getters['auth/getUser'].customRol.id == "be1ceec2-daf3-4bb8-8a3f-7a3a40e7659e" || //Encargado de Rutas
                store.getters['auth/getUser'].customRol.id == '6c72a0d5-7a67-43f4-81ff-1ec274c9a10b') //&& //Cajera
        },
        isAppRoutingOutputs() {
            //Si es rol administrador y es enrutamiento de salidas
            return router.currentRoute.name == 'apps-routings-outputs' && store.getters['auth/getUser'].customRol.id == "849fb91e-cab4-4894-a1c3-aedf03b47c34"

        },
        isInvoice () {
            return this.invoiceData.tipo_Docto == 'F' && store.getters['auth/getUser'].customRol.id != "5c1e9a77-e7a1-40fe-ba71-6d5c65cea4e0"
        },
        isAdministrator () {
            return store.getters['auth/getUser'].customRol.id == store.getters['auth/getAdministratorRol']
        },
        isCahsier() {
            return store.getters['auth/getUser'].customRol.id == store.getters['auth/getCashierRol'] //"6c72a0d5-7a67-43f4-81ff-1ec274c9a10b"
        },
        isSuperCashier() {
            return store.getters['auth/getUser'].customRol.id == store.getters['auth/getSuperCashierRol'] //"01ab07a3-50bf-483c-9fb0-b21c67b1b570"
        },
        isAuditor () {
            return store.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id ==  store.getters['auth/getAuditorRol'] : false
        },
        isAutorizator () {
            return store.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id == store.getters['auth/getAutorizatorRol'] : false
        },
        showTooltip() {
            return this.findSetting('ACTIVAR_ATAJOS_TECLADO')
        },
    },
    mounted() {
        
        // Escucha el evento de teclado cuando el componente esté montado
        window.addEventListener('keydown', this.handleKeyPress);
        window.addEventListener('resize', this.handleResize);
        this.handleResize()

        if (!this.isAutorizator || !this.isAuditor || !this.isSuperCashier || !this.isYard) {
            this.warehouse_id = store.getters['auth/getWarehouseId']
        }

        this.$nextTick(() => {
            this.wareHouses = store.getters['auth/getWareHouses']
            if (this.wareHouses.length > 0) {
                //Encontrar el almacen del usuario que viene de auth/getUser.almacenes
                if (!store.getters['auth/getWarehouseId']) {
                    if (store.getters['auth/getUser'].almacenes.length > 0) {
                        const wareHouse = this.wareHouses.find(wh => wh.almacen_Id == store.getters['auth/getUser'].almacenes[0].almacen_Id)
                        if (wareHouse) {
                            this.warehouse_id = wareHouse.almacen_Id
                            store.dispatch('auth/UPDATE_WAREHOUSE_ID', this.warehouse_id)
                        }
                    }
                } else {
                    this.warehouse_id = store.getters['auth/getWarehouseId']
                }
            }

            if (this.isSuperCashier) {
                store.dispatch('auth/UPDATE_WAREHOUSE_ID', 0)
                this.warehouse_id = 0
            }
        })
    },
    beforeDestroy() {
        
        // Limpia el listener de eventos cuando el componente se destruya
        window.removeEventListener('keydown', this.handleKeyPress);
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleKeyPress(event) {
            if (this.findSetting('ACTIVAR_ATAJOS_TECLADO')) {
                // Verifica si la tecla presionada es F12
                if (event.key === 'F12') {
                    event.preventDefault();  // Prevenir la acción predeterminada del navegador
                    // Emula el clic en el botón de guardar
                    this.$refs.saveButton.click();
                } else if (event.ctrlKey && event.key === 'e') {
                    event.preventDefault();  // Prevenir la acción predeterminada del navegador
                    this.$refs.editButton.click();
                } else if (event.ctrlKey && event.key === 'a') {
                    event.preventDefault();  // Prevenir la acción predeterminada del navegador
                    this.$refs.allButton.click();
                } else if (event.ctrlKey && event.key === 'r') {
                    event.preventDefault();  // Prevenir la acción predeterminada del navegador
                    this.resetForm()
                } else if (event.ctrlKey && event.key === 'c') {
                    event.preventDefault();  // Prevenir la acción predeterminada del navegador
                    this.cancelEditArticles()
                }
            }
        },
        markAsReceivedDocument() {
            const id = this.invoiceData.delivery_rounting.id
            this.$swal.fire({
                title: `Documento Recibido`,
                html: `¿Está seguro de que desea marcar el documento <b>${this.invoiceData.folio}</b> como recibido?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Continuar',
                cancelButtonText: 'Cancelar',
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                showClass: {
                    popup: "animate__animated animate__flipInX",
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    try {
                        this.loading = true
                        const { data } = await DeliveryRoutesResource.markAsReceivedDocument(id)
                        this.loading = false
                        if (data.isSuccesful) {
                            this.success(data.message)
                            await this.searchInvoices()
                        } else {
                            this.danger(data.message)
                        }
                    }catch(e) {
                        this.loading = false
                        this.handleResponseErrors(e)
                    }
                }
            })
        },
        setCustomerPass() {
            const payload = {
                usuario_Id: "string",
                empresa_Id: 0,
                sucursal_Id: store.getters['auth/getBranch'].sucursal_Id,
                docto_Pv_Id: this.invoiceData.docto_Pv_Id,
                docto_Pv_Fte_Id: this.invoiceData.docto_Pv_Fte_Id ? this.invoiceData.docto_Pv_Fte_Id : this.invoiceData.docto_Pv_Id,
                almacen_Id: this.invoiceData.almacen_Id,
                cliente_Id: this.invoiceData.cliente_Id,
                nombre_Cliente: this.invoiceData.nombre,
                nombre_Almacen: this.invoiceData.almacen,
                folio: this.invoiceData.folio,
                importe_Neto: this.invoiceData.importe_Neto,
                fecha_Factura: this.invoiceData.fecha,
                fecha: moment().format('YYYY-MM-DD'),
                tipo_Docto: this.invoiceData.tipo_Docto,
                nombre_Consig: this.invoiceData.nombre_Consig,
                calle: this.invoiceData.calle,
                colonia: this.invoiceData.colonia,
                num_Exterior: this.invoiceData.num_Exterior,
                num_Interior: this.invoiceData.num_Interior,
                codigo_Postal: this.invoiceData.codigo_Postal,
                telefono1: this.invoiceData.telefono1,
                ciudad_Id: this.invoiceData.ciudad_Id,
                nombre_Ciudad: this.invoiceData.ciudad,
                estado_Id: this.invoiceData.estado_Id,
                nombre_Estado: this.invoiceData.estado,
                direccion_Rerefencia: this.invoiceData.direccion_Referencia,
                direccion_Contacto: this.invoiceData.direccion_Contacto,
                estatus_Factura: this.invoiceData.estatus,
                nombre_Vendedor: this.invoiceData.vendedor,
                tipo_Cliente: this.invoiceData.tipo,
                plazo: this.invoiceData.plazo,
                dir_Cli_Id: this.invoiceData.dir_Cli_Id,
                estatus: "CLIENTE PASA",
                fecha_Alta: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                fecha_Actualizacion: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                rutasEntregasDetalles: this.invoiceData.articles.map(item => ({
                    rutas_Entregas_Id: 0,
                    docto_Pv_Det_Id: item.docto_Pv_Det_Id,
                    docto_Pv_Id: this.invoiceData.docto_Pv_Id,
                    articulo_Id: item.articulo_Id,
                    articulo_Nombre: item.articulo,
                    unidad_Medida: item.um,
                    unidad_Medida_Total: item.um_Total,
                    entregado: item.entregado,
                    devoluciones: item.devoluciones,
                    pendiente: item.pendiente,
                    cargado: this.isPartial ? item.pendiente : item.cargado,
                    estatus_Retorno: item.estatus_Retorno,
                    fecha_Alta: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                    fecha_Actualizacion: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                    centralRutasEntregasDTO: null,
                })),
                observaciones: ``,
                entrega_Inmediata: true,
            }

            this.$swal.fire({
                title: `Clientes Pasan`,
                html: `¿Está seguro de que desea pasar la factura <b>${payload.folio}</b> como Cliente Pasa?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Continuar',
                cancelButtonText: 'Cancelar',
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                showClass: {
                    popup: "animate__animated animate__flipInX",
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    try {
                        this.loading = true
                        const { data } = await DeliveryRoutesResource.setCustomersPassBy(payload)
                        this.loading = false
                        if (data.isSuccesful) {
                            this.success(data.message)
                            await this.searchInvoices()
                        } else {
                            this.danger(data.message)
                        }
                    }catch(e) {
                        this.loading = false
                        this.handleResponseErrors(e)
                    }
                }
            })
        },
        selectInvoice(e) {
            if (e) {
                console.error('selectInvoice', e)
                this.searchQuery = e.docto_Pv_Dest_Id
                this.$nextTick(async() => {
                    if (this.searchQuery) {
                        this.isDetail = true
                        await this.searchInvoices()
                    }
                })
            }
        },
        refocus() {
            this.$refs.searcInvoice.focus()
        },
        handleBlur() {
            // Indica que el input ha perdido el foco
            // store.commit('auth/SET_INPUT_FOCUS', false)         
            //this.refocus()
        },
        handleKeydown(event) {
            if (event.key === 'Enter') {
                // Procesar el código de barras
                console.log('Código de barras:', this.searcInvoice);
                this.searcInvoice = ''; // Limpiar el campo si es necesario
                this.refocus(); // Mantener el enfoque en el campo
            }
        },
        async storeComments() {

            let folioTickets = null;

            //Si existe el comentarios desde invoiceData.centralRutasEntregasObservaciones necesito obtener el Folio_Tickets
            if (this.invoiceData.centralRutasEntregasObservaciones.length > 0) {

                let tickets = this.invoiceData.centralRutasEntregasObservaciones;
                let lastFolioTicket = tickets[tickets.length - 1].folios_Tickets;

                //Obtengo la ultima posicion
                folioTickets = lastFolioTicket                
            } else {
                folioTickets = this.invoiceData.docto_Pv_Id + ',' + this.invoiceData.docto_Pv_Fte_Id;
            }

            try {
                this.loading = true
                const payload = {
                    id: 0,
                    usuario_Id: "string",
                    usuario_Nombre: "string",
                    comentarios: this.newComments,
                    folios_Tickets: folioTickets,
                    fecha_Alta: "2024-07-13T01:07:02.940Z"
                }

                const { data } = await DeliveryRoutesResource.storeComments(payload)
                this.loading = false
                if (data.isSuccesful) {
                    
                    //Ahora desde invoiceData.centralRutasEntregasObservaciones necesito agregar el nuevo comentario al inicio
                    this.invoiceData.centralRutasEntregasObservaciones.unshift(data.data)
                    this.newComments = ''

                } else {
                    this.danger(data.message)
                }
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        selectedWarehouse(evt) {
            
            this.searchQuery = null
            this.isEditInvoice = false
            this.isOutput = false
            this.invoiceData = {}
            this.delivery_rounting = {}
            this.transfers = []
            this.isReturn = false
            this.isDetail = false
            this.comments = null
            // this.$refs.searcInvoice.focus()
            
            if (evt) {
                this.$nextTick(() => {
                    store.dispatch('auth/UPDATE_WAREHOUSE_ID', evt)
                    EventBus.$emit('updateWarehouse', evt)    
                    this.refocus()
                })
            }
        },
        cancelEditArticles() {
            this.isEditInvoice = false
            this.isOutput = false
            this.isReturn = false
            this.isDetail = false
            this.comments = null
            this.showAutComplete = false
        },
        activateRefund() {
            this.isEditInvoice = true
            this.isReturn = true
            this.activeTab = 0

            if (this.isReturn) {

            }
        },
        handleResize() {
            this.isMobile = window.innerWidth < 576;
        }, 
        activarEdition() {
            this.isEditInvoice = true
            this.isOutput = true
            this.showAutComplete = true
            this.activeTab = 0
            this.comments = null
        },
        async generateTotalOutput() {
            this.$swal.fire({
                title: `Generar Salida Total`,
                html: '¿Está seguro de que desea generar la salida total de todos los artículos de la lista? <p>Esta acción no se puede revertir.</p>',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Continuar',
                cancelButtonText: 'Cancelar',
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                showClass: {
                    popup: "animate__animated animate__flipInX",
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                   await this.updateRouting(true);
                },
                allowOutsideClick: () => !Swal.isLoading(),
            })
        },
        async getDeliveryRoutesByDoctoPvId() {
            this.delivery_rounting = {}
            try {
                this.loading = true
                const { data } = await DeliveryRoutesResource.findByDoctoPvId(this.searchQuery)
                this.loading = false
                if (data.isSuccesful) {
                    this.delivery_rounting = data.data
                    this.invoiceData.delivery_rounting = {...data.data, folio_Real: this.invoiceData.folio, tipo_Docto_Real: this.invoiceData.tipo_Docto_Real, dias_Plazo: this.invoiceData.dias_Plazo}
                }
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        async getReturnsByTypeDocto() {
            this.returnItems = []
            const query = {
                doctoPvId: this.invoiceData.docto_Pv_Id,
                statusInvoice: this.invoiceData.estatus,
                typeDocto: "D",
            }

            try {
                this.loading = true
                const { data } = await DoctosPVResource.getReturnsByTypeDocto(query)
                this.loading = false
                if (data.isSuccesful) {
                    this.returnItems = data.data
                } else {
                    this.danger(data.message)
                }
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        async getReturns() {
            this.returns = []
            try {
                this.loading = true
                const { data } = await DeliveryRoutesResource.getReturns(this.invoiceData.docto_Pv_Id)
                this.loading = false
                if (data.isSuccesful) {
                    this.returns = data.data
                } else {
                    this.danger(data.message)
                }

            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        async getCustomer() {
            try {
                this.loading = true
                const { data } = await CustomersResource.find(this.invoiceData.cliente_Id)
                this.loading = false
                if (data.isSuccesful) {
                    this.customer = data.data
                    this.customer.address = this.customer.calle + ' ' + this.customer.num_Exterior + ' ' + this.customer.colonia
                    this.invoiceData.cliente = {...data.data}
                } else {
                    this.danger(data.message)
                }
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        chooseInvoice(item) {
            this.$emit("selected", { ...item });
            this.close();
        },
        async searchInvoices() {
            this.cliente_Pasa = false            
            this.delivery_rounting = {}
            if (!this.searchQuery) return this.danger(`Debe ingresar el folio o número de la factura a buscar`)

            const query = {
                soloFacturados: true,
            }

            if (this.isSuperCashier) {
                query.almacen_Id = this.warehouse_id
            }

            try {
                this.loading = true
                const { data } = await DoctosPVResource.getHeader(this.searchQuery, query)
                this.loading = false
                if (data.isSuccesful) {
                    this.invoiceData = { ...data.data[0], tipo_Docto: data.data[0].tipo_Docto.trim(), tipo_Docto_Real: data.data[0].tipo_Docto.trim() }
                    this.invoiceData.entrega_Inmediata = true
                    this.invoiceData.documento_Recibido = false
                    this.searchQuery = this.invoiceData.docto_Pv_Id

                    //Activamos el tab de articulos
                    this.activeTab = this.activeTab !== 0 ? 0 : this.activeTab;

                    if (this.showArticles) {
                        await this.getInvoiceArticles()
                    }

                    //Find Rounting
                    await this.getDeliveryRoutesByDoctoPvId()

                    this.$nextTick(() => {
                        if (this.hasDeliveryRounting) {
                            this.articles.forEach(element => {
                                const finded = this.delivery_rounting.rutasEntregasDetalles.find(item => item.articulo_Id == element.articulo_Id);
                                if (finded) {                                    
                                    element.cargado = parseFloat(finded.cargado); // Convertir a número
                                }                                
                            });
                        }

                        //Obtener el total de articulos pendientes
                        this.countPending = this.articles.reduce((carry, a) => {
                            return carry + a.pendiente
                        }, 0)

                        //Obtener el total de articulos entregados
                        this.countDelivered = this.articles.reduce((carry, a) => {
                            return carry + a.entregado
                        }, 0)

                        this.$nextTick(() => {
                            if (this.hasDeliveryRounting) {
                                this.transfers = this.invoiceData.delivery_rounting.rutasEntregasTraspasos
                                    .sort((a, b) => new Date(b.fecha_Alta) - new Date(a.fecha_Alta));

                                //Encontrar la direccion del cliente
                                const address = this.invoiceData.delivery_rounting.cliente.direcciones.find(item => item.dir_cli_Id == this.invoiceData.dir_cli_Id)
                                if (address) {
                                    this.invoiceData.dir_cli_Id = address.dir_cli_Id
                                    this.invoiceData.calle = address.calle
                                    this.invoiceData.direccion_Referencia = address.referencia
                                    this.invoiceData.telefono1 = address.telefono1
                                    this.invoiceData.direccion_Contacto = address.contacto
                                    this.invoiceData.num_Exterior = address.num_Exterior
                                    this.invoiceData.num_Interior = address.num_Interior  
                                    this.invoiceData.colonia = address.colonia
                                    this.invoiceData.codigo_Postal = address.codigo_Postal
                                    this.invoiceData.ciudad = address.ciudad
                                    this.invoiceData.estado = address.estado

                                    this.invoiceData.delivery_rounting.dir_cli_Id = address.dir_cli_Id
                                    this.invoiceData.delivery_rounting.calle = address.calle
                                    this.invoiceData.delivery_rounting.direccion_Referencia = address.referencia
                                    this.invoiceData.delivery_rounting.telefono1 = address.telefono1
                                    this.invoiceData.delivery_rounting.direccion_Contacto = address.contacto
                                    this.invoiceData.delivery_rounting.num_Exterior = address.num_Exterior
                                    this.invoiceData.delivery_rounting.num_Interior = address.num_Interior  
                                    this.invoiceData.delivery_rounting.colonia = address.colonia
                                    this.invoiceData.delivery_rounting.codigo_Postal = address.codigo_Postal
                                    this.invoiceData.delivery_rounting.ciudad = address.ciudad
                                    this.invoiceData.delivery_rounting.estado = address.estado
                                } 
                                
                                //Verificar
                                if (this.delivery_rounting) {

                                    console.error('this.delivery_rounting', this.delivery_rounting)

                                    this.cliente_Pasa = this.delivery_rounting.cliente_Pasa
                                    this.invoiceData.documento_Recibido = this.delivery_rounting.documento_Recibido
                                    this.documento_Recibido = this.delivery_rounting.documento_Recibido
                                    
                                    // if (this.delivery_rounting.estatus === 'CERRADA' || this.delivery_rounting.documento_Recibido) {
                                    //     this.documento_Recibido = false
                                    // }
                                }
                            }
                        })

                        this.invoiceData.tipo_Docto = data.data[0].tipo_Docto.trim()
                        this.$emit("selected", this.invoiceData);
                        this.$emit("onSearch", this.searchQuery)
                        this.$emit("resetSearch");
                    })                    

                } else {
                    this.searchQuery = null
                    this.danger(data.message)
                }

                console.log(this.invoiceData)

            } catch (e) {
                this.searchQuery = null
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        async getInvoiceBalance() {
            try {
                this.loading = true
                const { data } = await DoctosPVResource.getInvoiceBalance(this.invoiceData.docto_Pv_Id)
                this.loading = false
                if (data.isSuccesful) {
                    this.invoiceData.balance = data.data // ?? 0

                } else {
                    this.danger(data.message)
                }
            } catch (e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        async getInvoiceArticles() {
            try {
                this.loading = true
                const { data } = await DoctosPVResource.getArticles(this.invoiceData.docto_Pv_Id, this.invoiceData.tipo_Docto)
                this.loading = false
                if (data.isSuccesful) {
                    this.articles = data.data.map(item => ({...item, cargado: 0, oldPending: parseFloat(item.pendiente)}))
                    this.invoiceData.articles = this.articles
                } else {
                    this.danger(data.message)
                }
            } catch (e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        async routeInvoice() {   
            
            if (this.invoiceData.tipo_Docto == 'V') {
                return this.danger('No se puede enrutar un ticket de venta')
                return false
            }

            const details = []
            this.articles.forEach(article => {                       
                details.push({
                    articulo_Id: article.articulo_Id,
                    articulo_Nombre: article.articulo,
                    cargado: 0,
                    devoluciones: article.devoluciones,
                    docto_Pv_Det_Id: article.docto_Pv_Det_Id ? article.docto_Pv_Det_Id : this.invoiceData.docto_Pv_Id,
                    docto_Pv_Id: this.invoiceData.docto_Pv_Id,
                    enrutado: true,
                    entregado: 0,
                    estatus_Retorno: article.estatus_Retorno,
                    fecha_Actualizacion: moment(),
                    fecha_Alta: moment(),
                    id: 0,
                    pendiente: article.pendiente,
                    rutas_Entregas_Id: 0, //this.invoiceData.delivery_rounting.id,
                    unidad_Medida: article.um,
                    unidad_Medida_Total: article.um_Total,
                    unidades: this.isReturn && article.cargado > 0 ? ( article.cargado * -1 ): article.pendiente,
                    chofer_Id: 1,
                    camion_Id: 1,
                    nombre_Camion: ' -- ',
                    nombre_Chofer: ' -- ',
                    isReturn: false,
                })
            });

            try {

                this.loading = true
                const { data } = await DeliveryRoutesResource.canRouting(this.invoiceData.docto_Pv_Id, this.invoiceData.tipo_Docto, details)
                this.loading = false

                if (data.isSuccesful) {
                    if (data.data) {
                        this.$swal.fire({
                            title: `Enrutar Factura ${this.invoiceData.folio}`,
                            text: '¿Está seguro de querer clasificar este documento como pre-ruta?',
                            icon: 'question',
                            showCancelButton: true,
                            confirmButtonText: 'Continuar',
                            cancelButtonText: 'Cancelar',
                            customClass: {
                                confirmButton: "btn btn-primary",
                                cancelButton: "btn btn-outline-danger ml-1",
                            },
                            showClass: {
                                popup: "animate__animated animate__flipInX",
                            },
                            buttonsStyling: false,
                        }).then( async (result) => {
                            if (result.isConfirmed) {
                                this.$emit('onPreRoute')
                            }
                        })
                    }
                } else {
                    this.danger(data.message)
                    this.resetForm()
                } 
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
                this.resetForm()
            }
        },
        async storeReturn() {

            const details = []
            const operator = this.hasDeliveryRounting ? this.invoiceData.delivery_rounting.webOperadores : null
            const truck = this.hasDeliveryRounting ? this.invoiceData.delivery_rounting.webCamiones : null
            this.articles.forEach(article => {                       
                details.push({
                    articulo_Id: article.articulo_Id,
                    articulo_Nombre: article.articulo,
                    cargado: 0,
                    devoluciones: article.devoluciones,
                    docto_Pv_Det_Id: article.docto_Pv_Det_Id ? article.docto_Pv_Det_Id : this.invoiceData.docto_Pv_Id,
                    docto_Pv_Id: this.invoiceData.docto_Pv_Id,
                    enrutado: true,
                    entregado: 0,
                    estatus_Retorno: article.estatus_Retorno,
                    fecha_Actualizacion: moment(),
                    fecha_Alta: moment(),
                    id: 0,
                    pendiente: article.pendiente,
                    rutas_Entregas_Id: this.hasDeliveryRounting ? this.invoiceData.delivery_rounting.id : 0,
                    unidad_Medida: article.um,
                    unidad_Medida_Total: article.um_Total,
                    unidades: this.isReturn && article.cargado > 0 ? ( article.cargado * -1 ): (article.cargado),
                    chofer_Id: this.hasDeliveryRounting ? this.invoiceData.delivery_rounting.chofer_Id : 1,
                    camion_Id: this.hasDeliveryRounting ? this.invoiceData.delivery_rounting.camion_Id : 1,
                    nombre_Camion: truck ? truck.identificador + ' ' + truck.placas : ' -- ',
                    nombre_Chofer: operator ? operator.nombre + ' ' + operator.apellidos : ' -- ',
                    isReturn: true,
                    comentarios: this.comments,
                })
            });

            //Generamos el insert de salida
            const newDetails = details.filter(item => item.unidades != 0)

            try {
                this.loading = true
                const { data } = await DeliveryRoutesResource.output(newDetails)
                console.log(data)
                this.loading = false                
                if(data.isSuccesful) {
                    this.isEditInvoice = false
                    this.isOutput = false
                    this.isReturn = false
                    //EventBus.$emit('realodRoutings', true)
                    this.success(`Devolución realizada con éxito`)
                    await this.searchInvoices()
                } else {
                    if (data.data && data.data.length > 0 && data.data[0].isReload) {
                        this.$swal.fire({
                            title: `Error al generar la devolución`,
                            text: data.message,
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonText: 'Recargar',
                            cancelButtonText: 'Cancelar',
                            customClass: {
                                confirmButton: "btn btn-primary",
                                cancelButton: "btn btn-outline-danger ml-1",
                            },
                            showClass: {
                                popup: "animate__animated animate__flipInX",
                            },
                            buttonsStyling: false,
                        }).then( async (result) => {
                            await this.searchInvoices()
                        })
                    } else {
                        this.danger(data.message)
                    }
                }
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        async updateRouting (isAll = false) {
            const details = []

            if (this.hasDeliveryRounting) {
                const operator = this.invoiceData.delivery_rounting.webOperadores
                const truck = this.invoiceData.delivery_rounting.webCamiones

                this.articles.forEach(article => {                       
                    details.push({
                        articulo_Id: article.articulo_Id,
                        articulo_Nombre: article.articulo,
                        cargado: 0,
                        devoluciones: article.devoluciones,
                        docto_Pv_Det_Id: article.docto_Pv_Det_Id ? article.docto_Pv_Det_Id : this.invoiceData.docto_Pv_Id,
                        docto_Pv_Id: this.invoiceData.docto_Pv_Id,
                        enrutado: true,
                        entregado: 0,
                        estatus_Retorno: article.estatus_Retorno,
                        fecha_Actualizacion: moment(),
                        fecha_Alta: moment(),
                        id: 0,
                        pendiente: article.pendiente,
                        rutas_Entregas_Id: this.invoiceData.delivery_rounting.id,
                        unidad_Medida: article.um,
                        unidad_Medida_Total: article.um_Total,
                        unidades: this.isReturn && article.cargado > 0 ? ( article.cargado * -1 ): ( isAll ? article.pendiente : article.cargado),
                        chofer_Id: operator ? operator.id : 1, // this.invoiceData.delivery_rounting.chofer_Id,
                        camion_Id: truck ? truck.id : 1, //this.invoiceData.delivery_rounting.camion_Id,
                        nombre_Camion: truck ? truck.identificador + ' ' + truck.placas : ' -- ',
                        nombre_Chofer: operator ? operator.nombre + ' ' + operator.apellidos : ' -- ',
                        isReturn: false,
                        clave_Articulo: article.clave_Articulo,
                        comentarios: this.comments,
                    })
                });

                //Generamos el insert de salida
                const newDetails = this.isReturn ? details.filter(item => item.unidades != 0) : details.filter(item => item.unidades > 0)
                
                if (this.isReturn) {

                    //Validar si al menos hay una unidad mayor a 0 y convertir en positivo las unidades
                    const hasUnits = newDetails.some(item => Math.abs(item.unidades) > 0)
                    if (!hasUnits) {
                        this.danger('No se puede realizar una devolución sin unidades')
                        return
                    }

                    this.isActiveAuthorization = true
                    return
                } else {
                    //Validar si al menos hay una unidad mayor a 0
                    const hasUnits = newDetails.some(item => item.unidades > 0)
                    if (!hasUnits) {
                        this.danger('No se puede realizar una salida sin unidades')
                        return
                    }
                }

                try {
                    this.loading = true
                    const { data } = await DeliveryRoutesResource.output(newDetails)
                    this.loading = false                
                    if(data.isSuccesful) {
                        this.isEditInvoice = false
                        this.isOutput = false
                        this.isReturn = false
                        this.comments = null
                        //EventBus.$emit('realodRoutings', true)
                        this.success(data.message)
                        await this.searchInvoices()
                    } else {
                        if (data.data && data.data.length > 0 && data.data[0].isReload) {
                            this.$swal.fire({
                                title: `Error al generar la salida`,
                                text: data.message,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Recargar',
                                cancelButtonText: 'Cancelar',
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                    cancelButton: "btn btn-outline-danger ml-1",
                                },
                                showClass: {
                                    popup: "animate__animated animate__flipInX",
                                },
                                buttonsStyling: false,
                            }).then( async (result) => {
                                await this.searchInvoices()
                            })
                        } else {
                            this.danger(data.message)
                        }
                    }
                }catch(e) {
                    this.loading = false
                    this.handleResponseErrors(e)
                }

            } else {
                this.articles.forEach(article => {
                    details.push({
                        articulo_Id: article.articulo_Id,
                        articulo_Nombre: article.articulo,
                        cargado: 0,
                        devoluciones: article.devoluciones,
                        docto_Pv_Det_Id: article.docto_Pv_Det_Id ? article.docto_Pv_Det_Id : this.invoiceData.docto_Pv_Id,
                        docto_Pv_Id: this.invoiceData.docto_Pv_Id,
                        enrutado: true,
                        entregado: 0,
                        estatus_Retorno: article.estatus_Retorno,
                        fecha_Actualizacion: moment(),
                        fecha_Alta: moment(),
                        id: 0,
                        pendiente: article.pendiente,
                        rutas_Entregas_Id: 0,
                        unidad_Medida: article.um,
                        unidad_Medida_Total: article.um_Total,
                        unidades: isAll ? article.pendiente : article.cargado,
                        chofer_Id: 1,
                        camion_Id: 1,
                        nombre_Camion: ' -- ',
                        nombre_Chofer: ' -- ',
                        clave_Articulo: article.clave_Articulo,
                    })
                });

                //Generamos el insert del enrutamiento
                try {

                    const payload = {
                        usuario_Id: "string",
                        empresa_Id: 0,
                        sucursal_Id: store.getters['auth/getBranch'].sucursal_Id,
                        docto_Pv_Id: this.invoiceData.docto_Pv_Id,
                        docto_Pv_Fte_Id: this.invoiceData.docto_Pv_Fte_Id ? this.invoiceData.docto_Pv_Fte_Id : this.invoiceData.docto_Pv_Id,
                        almacen_Id: this.invoiceData.almacen_Id,
                        cliente_Id: this.invoiceData.cliente_Id,
                        nombre_Cliente: this.invoiceData.nombre,
                        nombre_Almacen: this.invoiceData.almacen,
                        folio: this.invoiceData.folio,
                        importe_Neto: this.invoiceData.importe_Neto,
                        fecha_Factura: this.invoiceData.fecha,
                        fecha: moment().format('YYYY-MM-DD'),
                        tipo_Docto: this.invoiceData.tipo_Docto,
                        nombre_Consig: this.invoiceData.nombre_Consig,
                        calle: this.invoiceData.calle,
                        colonia: this.invoiceData.colonia,
                        num_Exterior: this.invoiceData.num_Exterior,
                        num_Interior: this.invoiceData.num_Interior,
                        codigo_Postal: this.invoiceData.codigo_Postal,
                        telefono1: this.invoiceData.telefono1,
                        ciudad_Id: this.invoiceData.ciudad_Id,
                        nombre_Ciudad: this.invoiceData.ciudad,
                        estado_Id: this.invoiceData.estado_Id,
                        nombre_Estado: this.invoiceData.estado,
                        direccion_Rerefencia: this.invoiceData.direccion_Referencia,
                        direccion_Contacto: this.invoiceData.direccion_Contacto,
                        estatus_Factura: this.invoiceData.estatus,
                        nombre_Vendedor: this.invoiceData.vendedor,
                        tipo_Cliente: this.invoiceData.tipo,
                        plazo: this.invoiceData.plazo,
                        dir_Cli_Id: this.invoiceData.dir_Cli_Id,
                        estatus: "SALIDA TICKET",
                        chofer_Id: 1,
                        camion_Id: 1,
                        fecha_Alta: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                        fecha_Actualizacion: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                        rutasEntregasDetalles: this.articles.map(item => ({
                            rutas_Entregas_Id: 0,
                            docto_Pv_Det_Id: item.docto_Pv_Det_Id,
                            docto_Pv_Id: this.invoiceData.docto_Pv_Id,
                            articulo_Id: item.articulo_Id,
                            articulo_Nombre: item.articulo,
                            unidad_Medida: item.um,
                            unidad_Medida_Total: item.um_Total,
                            entregado: item.entregado,
                            devoluciones: item.devoluciones,
                            pendiente: item.pendiente,
                            cargado: isAll ? item.pendiente : item.cargado,
                            unidades: isAll ? item.pendiente : item.cargado,
                            estatus_Retorno: item.estatus_Retorno,
                            chofer_Id: 1,
                            camion_Id: 1,
                            nombre_Camion: ' -- ',
                            nombre_Chofer: ' -- ',
                            fecha_Alta: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                            fecha_Actualizacion: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                            centralRutasEntregasDTO: null,
                            isReturn: false,
                            clave_Articulo: item.clave_Articulo,
                        })),
                        observaciones: null, //this.observaciones,
                        entrega_Inmediata: true, //this.invoiceData.entrega_Inmediata,
                        comentarios: this.comments,
                    }

                    //Validamos si es return
                    if (this.isReturn) {

                        //Validar si al menos hay una unidad mayor a 0 y convertir en positivo las unidades
                        const hasReturnUnits = payload.rutasEntregasDetalles.some(item => Math.abs(item.unidades) > 0)
                        if (!hasReturnUnits) {
                            this.danger('No se puede realizar una devolución sin unidades')
                            return
                        }

                        this.isActiveAuthorization = true
                        return
                    }

                    //De payload -> rutasEntregasDetalles necesito validar que al menos una unidad cargada debe ser mayor a 0 de lo contrario no me debe dejar continuar
                    const hasUnits = payload.rutasEntregasDetalles.some(item => item.cargado > 0)
                    if (!hasUnits) {
                        this.danger('No se puede realizar una salida sin unidades')
                        return false
                    }                   

                    this.loading = true
                    const { data } = await DeliveryRoutesResource.store(payload)
                    this.loading = false
                    if (data.isSuccesful) {
                        this.isEditInvoice = false
                        this.isOutput = false
                        this.comments = null
                        //EventBus.$emit('realodRoutings', true)
                        await this.searchInvoices()    
                        this.success(`Salida generada con éxito`)
                    } else {
                        if (data.data && data.data.isReload) {
                            this.$swal.fire({
                                title: `Error al generar la salida`,
                                text: data.message,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Recargar',
                                cancelButtonText: 'Cancelar',
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                    cancelButton: "btn btn-outline-danger ml-1",
                                },
                                showClass: {
                                    popup: "animate__animated animate__flipInX",
                                },
                                buttonsStyling: false,
                            }).then( async (result) => {
                                await this.searchInvoices()
                            })
                        } else {
                            this.danger(data.message)
                        }
                    }
                }catch(e) {
                    this.loading = false
                    this.handleResponseErrors(e)
                }
            }            
        },
        async resetForm() {
            this.searchQuery = null
            this.isEditInvoice = false
            this.isOutput = false
            this.invoiceData = {}
            this.delivery_rounting = {}
            this.transfers = []
            this.isReturn = false
            this.isDetail = false
            this.comments = null
            this.$refs.searcInvoice.focus()
            // EventBus.$emit('realodRoutings', true)
            EventBus.$emit('realodRoutings2', true)
        },
        close() {
            this.resetForm()
            this.isEditInvoice = false
            this.isOutput = false
            this.isReturn = false
            this.isDetail = false
            this.$refs['modal-search-invoice'].hide()
            this.$emit("close");
        },
    },
    watch: {
        getIsInputFocused(value) {
            if (value) {
                this.$refs.searcInvoice.focus()
            }
        },
        isEditInvoice (value) {
            if(!value) {
                this.articles = this.articles.map(item => ({...item, cargado: 0, pendiente: item.oldPending}))
            }
        },
        activeTab(value) {
            if (value > 0) {
                this.isEditInvoice = false
                this.isOutput = false
                this.isReturn = false                
            }
        },
        articles: {
            deep: true,
            handler(newArticles, oldArticles) {
               
            }
        },
        isAutoCompleteCharge(value) { 
            if (value) {
                this.articles = this.articles.map(item => ({...item, cargado: item.pendiente > 0 ? item.pendiente : 0 }))
            } else {
                this.articles = this.articles.map(item => ({...item, cargado: 0 }))
            }
        },
        activeSearchInvoice(value) {
            if (value) {
                this.invoiceData = null
                this.searchQuery = null
                this.articles = []
                this.$refs['modal-search-invoice'].toggle()
            } else {
                this.close()
            }
        },
        searchQuery(value) {
            if (!value) {

                //this.searchQuery = null
                this.isEditInvoice = false
                this.isOutput = false
                this.invoiceData = {}
                this.delivery_rounting = {}
                this.transfers = []
                this.isReturn = false
                this.isDetail = false
                this.$refs.searcInvoice.focus()
                this.invoiceData = null
                this.articles = []
                this.$emit('resetForm')
            } else {
                //this.searchInvoices()
            }
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>