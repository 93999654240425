import axios from '@/libs/axios'

export default class WebCamiones {

    index(query) {
        return axios.get(`/v2/WebCamiones/GetAll`, {params: query});    
    }

    store(payload) {
        return axios.post(`/v2/WebCamiones/Store`, payload, {
            // headers: {
            //     'Content-Type': 'multipart/form-data',             
            // }
        });
    }

    update(payload) {
        return axios.post(`/v2/WebCamiones/Update`, payload, {
            // headers: {
            //     'Content-Type': 'multipart/form-data',             
            // }
        });
    }

    destroy(id) {
        return axios.put(`/v2/WebCamiones/Destroy/${id}`);    
    }
}
